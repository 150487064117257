import rpc from "@/rpc";

class CRUDService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    get(entity, id) {
        return this.rpc.execute('get', {
            entity: entity,
            id: +id,
        })
    }

    create(entity, data) {
        return this.rpc.execute('create', {
            entity: entity,
            data: data,
        })
    }

    update(entity, data, id) {
        return this.rpc.execute('update', {
            entity: entity,
            id: +id,
            data: data,
        })
    }

    delete(entity, id) {
        return this.rpc.execute('delete', {
            entity: entity,
            id: +id
        })
    }
}

export default new CRUDService(rpc)
