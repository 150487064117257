<template>
  <div class="grid">
    <div class="col-12 lg:col-4">
      <Card>
        <template #title>Остановка передачи в БКИ по заявке</template>
        <template #content>
          <AbstractForm
            ref="form"
            :loading="loading"
            v-model="form"
            :on-commit="processStop"
            commit-label="Остановить"
            commit-icon="pi pi-stop"
          ></AbstractForm>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractForm from "@/components/AbstractForm";
import FormModel from "@/model/FormModel";
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";
export default {
  name: "StopLoad",
  components: {AbstractForm},
  data() {
    return {
      loading: false,
      form: {
        applicationUuid: new FormModel('UUID заявки / займа')
      },
    }
  },
  methods: {
    processStop() {
      this.$confirm.require({
        message: 'Вы уверены, что хотите остановить передачу по заявке "' + this.form.applicationUuid.value + '"?',
        header: 'Подтвердите остановку передачи',
        acceptLabel: 'Остановить',
        acceptIcon: 'pi pi-stop',
        rejectLabel: 'Отмена',
        rejectIcon: 'pi pi-ban',
        accept: () => {
          this.$confirm.close()
          this.loading = true
          CRUDService.create('send_stop', {
            type: 'manual',
            applicationUuid: typeof this.form.applicationUuid.value === 'string' ? this.form.applicationUuid.value.trim() : null
          }).then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Передача остановлена',
              detail: 'Передача данных в БКИ по заявке остановлена',
              life: 2000
            })
            this.$refs.form.clear()
            this.loading = false
          }).catch((err) => {
            ResponseService.handleErrorResponseWithForm(err, this.form, this.$toast)
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>